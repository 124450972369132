import { AccessTokens, UserProfile } from '../../types';

const userProfileMock: UserProfile = {
  date_format: '%m/%d/%Y',
  email: 'user.name@email.com',
  entity_type: 'membership',
  fy_end_day: 31,
  fy_end_month: 12,
  internal_admin_role: 'acl_super_admin',
  locale: 'en',
  name: 'User Name',
  org_admin: true,
  org_id: '1',
  org_locale: 'en',
  org_name: 'Test Org',
  region_code: 'US',
  reports_role: 'no_access',
  sub: '1|QyWh9cyzTAwEqimNdK5K',
  subdomain: 'test-org',
  subscription_state: 'active',
  timezone: 'Pacific Time (US & Canada)',
  timezone_offset: '-07:00',
  uid: 'QyWh9cyzTAwEqimNdK5K',
  user_type: 'user',
  iss: 'https://oidc-us.highbond-s3.com',
  aud: 'organization_settings',
  exp: 1724264639,
  iat: 1724264639,
};

export const mockAccessTokens: AccessTokens = {
  identityApi: 'identity-mock-access-token',
  organizationApi: 'org-mock-access-token',
};

export default userProfileMock;
