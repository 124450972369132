import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import App from './routes/App.tsx';
import queryClient from './queryClient.ts';
import setupI18next from './helpers/setupI18next.ts';
import addScript from './helpers/addScript.ts';
import setupMSW from './helpers/setupMSW.ts';
import AppConfig from './AppConfig.ts';
import OidcProvider from './components/OidcProvider';

setupI18next();
addScript(AppConfig.pendoMonitorScript);
addScript(AppConfig.microFrontends['hb-global-navigator'].url);

if (process.env.NODE_ENV === 'development') {
  await setupMSW();
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <OidcProvider>
        <App />
      </OidcProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
