import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SpinnerBackdrop, SpinnerContainer } from './LoadingSpinner.styles';

interface LoadingSpinnerProps {
  withBackdrop?: boolean;
  withoutText?: boolean;
}
export default function LoadingSpinner({ withBackdrop, withoutText }: LoadingSpinnerProps) {
  const { t } = useTranslation();
  const Container = SpinnerContainer(withBackdrop);
  return (
    <>
      <Container>
        <CircularProgress />
        {!withoutText && <Typography variant="subtitle1">{t('security_settings.loading')}</Typography>}
      </Container>
      {withBackdrop && <SpinnerBackdrop open sx={{ position: 'absolute' }} />}
    </>
  );
}
