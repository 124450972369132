import { Box, Backdrop } from '@mui/material';

import { styled } from '@mui/material/styles';

export const SpinnerContainer = (backdrop?: boolean) => {
  if (backdrop) {
    return styled(Box)(({ theme }) => ({
      '&&': {
        position: 'absolute',
        zIndex: theme.zIndex.drawer + 2,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }));
  }
  return styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));
};

export const SpinnerBackdrop = styled(Backdrop)(({ theme }) => ({
  '&&': {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    background: theme.tokens.semantic.color.background.base.value,
  },
}));
