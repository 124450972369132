import { getPlatformUrlParts } from '@acl-services/highbond-common';

const {
  protocol,
  orgSubdomain,
  domain,
  region,
  domainSuffix,
  tld,
  isLocal,
  isDev,
  isStaging,
  isPreprod,
  isProduction,
  isGovCloud,
} = getPlatformUrlParts(window.location.origin, true);

const isLocalhost = isLocal || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
const nonUsRegionalSuffix = region !== 'us' ? `-${region}` : '';

const host = `${domain}${domainSuffix}.${tld}`;

const AppConfig = {
  homeUrl: `${protocol}://${orgSubdomain}.home${nonUsRegionalSuffix}.${host}`,
  launchpadUrl: `${protocol}://accounts.${host}`,
  loginUrl: `${protocol}://accounts.${host}/login`,
  oidcClientUrl: isLocalhost ? 'http://localhost:5173' : `${protocol}://oidc-${region}.${host}`,
  organizationsApiUrl: `${protocol}://organizations.${host}`,
  pendoMonitorScript: `${protocol}://monitor${nonUsRegionalSuffix}.${host}/v1/monitor.js`,
  isLocal,
  isDev,
  isStaging,
  isProduction,
  isProductionLike: isProduction || isPreprod,
  isGovCloud,
  region,
  orgSubdomain: isLocal ? 'test-org' : orgSubdomain,
  microFrontends: {
    'hb-global-navigator': {
      url: `${protocol}://web-components${nonUsRegionalSuffix}.${host}/global-navigator/index.js`,
    },
  },
};

export default AppConfig;

export const AppRoutes = {
  ROOT_PATH: 'security-settings',
  UNAUTHORIZED: 'unauthorized',
  OIDC_CALLBACK: 'oidc/callback',
  LOGOUT_CALLBACK: 'logout',
  LOGIN_CALLBACK: 'login/callback',
};
