import React, { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import GlobalNavigator from '../components/GlobalNav/GlobalNav';
import LoadingSpinner from '../components/LoadingSpinner';
import { getI18n, useTranslation } from 'react-i18next';
import { useUser } from '../components/OidcProvider/OidcProvider.tsx';
import { Localization } from '@mui/material/locale';
import { muiLocales } from '../locales/muiLocales.ts';
import { FALLBACK_LNG } from '../locales';
import { UserProfile } from '../types';
import { lensThemeOptions } from '@diligentcorp/atlas-theme-mui/lib/themes/lens/index.js';
import { AtlasThemeProvider } from '@diligentcorp/atlas-theme-mui';
import { AppRoutes } from '../AppConfig.ts';

const SecuritySettings = lazy(() => import('./security-settings/SecuritySettings.tsx'));
const Unauthorized = lazy(() => import('./unauthorized/Unauthorized.tsx'));

export default function App() {
  const { t } = useTranslation();
  const { userProfile, isSessionLoading, oidcError } = useUser();
  const [locale, setLocale] = React.useState<Localization[]>(muiLocales[FALLBACK_LNG]);

  function updateHtmlLocale(locale: string) {
    const I18n = getI18n();
    I18n.changeLanguage(locale).then((_resolve) => (document.documentElement.lang = locale));
  }

  function updatePageTitle() {
    document.title = t('security_settings.tab_title', {
      page_name: t('security_settings.title'),
      platform_name: t('platform_name'),
    });
  }

  React.useEffect(() => {
    if (userProfile) {
      const { locale } = userProfile as UserProfile;
      setLocale(muiLocales[locale]);

      updateHtmlLocale(locale);
      updatePageTitle();
    }
  }, [userProfile]);

  if (isSessionLoading) {
    return (
      <AtlasThemeProvider locales={locale} themeOptions={lensThemeOptions}>
        <LoadingSpinner withBackdrop withoutText />
      </AtlasThemeProvider>
    );
  }

  if (oidcError) {
    return (
      <AtlasThemeProvider locales={locale} themeOptions={lensThemeOptions}>
        <Unauthorized />
      </AtlasThemeProvider>
    );
  }

  return (
    <>
      <GlobalNavigator />
      <AtlasThemeProvider locales={locale} themeOptions={lensThemeOptions}>
        <main data-atlas-gn-app="true">
          <BrowserRouter>
            <Routes>
              <Route
                path={AppRoutes.ROOT_PATH}
                element={
                  <React.Suspense fallback={<LoadingSpinner withBackdrop withoutText />}>
                    <SecuritySettings />
                  </React.Suspense>
                }
              />
              <Route path={AppRoutes.UNAUTHORIZED} element={<Unauthorized />} />,
              <Route path={AppRoutes.OIDC_CALLBACK} element={<LoadingSpinner withBackdrop withoutText />} />,
              <Route path={AppRoutes.LOGOUT_CALLBACK} element={<LoadingSpinner withBackdrop withoutText />} />,
              <Route path={AppRoutes.LOGIN_CALLBACK} element={<LoadingSpinner withBackdrop withoutText />} />,
              <Route path="*" element={<Navigate to={AppRoutes.ROOT_PATH} />} />,
            </Routes>
          </BrowserRouter>
        </main>
      </AtlasThemeProvider>
    </>
  );
}
