// Note: localization for MUI data grid and MUI date picker can be added into this file too.
import { deDE, enUS, esES, frFR, itIT, jaJP, nlNL, ptBR, zhCN, Localization } from '@mui/material/locale';

export const muiLocales: Record<string, Localization[]> = {
  de: [deDE],
  en: [enUS],
  es: [esES],
  fr: [frFR],
  it: [itIT],
  ja: [jaJP],
  nl: [nlNL],
  pt: [ptBR],
  zh: [zhCN],
};
